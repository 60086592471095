<template>
  <v-img
    src="@/assets/bg_register_agency.png"
    lazy-src="@/assets/bg_register_agency_small.jpg"
    height="100vh"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
      :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
    >
      <v-card
        class="justify-center overflow-auto"
        max-width="600"
        color="white"
      >
        <v-card-title
          class="d-flex align-center justify-center mt-3"
          style="color: #6038f7"
        >
          Join as a Agency
        </v-card-title>
        <v-card-title class="mx-3">
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="name"
                label="Agency Name*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
                type="name"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="email"
                label="Agency Master Email*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                type="email"
                :rules="[rules.required, rules.email]"
                autocomplete="off"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0 mb-5">
              <AppPhoneNumberInput
                v-model="phone"
                @input="onChange"
                @update="onUpdatePhone"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0">
              <v-text-field
                v-model="phone_ext"
                label="Phone Ext"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="contact_person"
                label="Contact Person*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="contact_title"
                label="Contact Title*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="address"
                label="Address*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                type="address"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="address2"
                label="Address 2"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-text-field
                v-model="city"
                label="City*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-select
                v-model="state"
                label="State*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :items="stateOptions"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-text-field
                v-model="zip"
                label="Zip code*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                type="zip"
                autocomplete="off"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <input type="text" style="display:none;" />
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @input="onChange"
                placeholder="Password*"
                dense
                filled
                outlined
                background-color="white"
                class="pb-0 mb-0"
                full-width
                style="width: 100%"
                autocomplete="new-password"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <input type="text" style="display:none;" />
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @input="onChange"
                placeholder="Confirm password*"
                dense
                filled
                outlined
                background-color="white"
                class="pb-0 mb-0"
                full-width
                style="width: 100%"
                autocomplete="new-password"
              />
            </v-col>
          </v-row>
          <div style="width: 100%">
            <v-btn
              rounded
              color="#7024c4"
              width="100%"
              @click="onSignUp"
              :loading="loading"
              class="mt-5 white--text"
              >Join</v-btn
            >
            <v-btn
              text
              class="text-none mb-4 mt-4"
              block
              rounded
              @click="onBack()"
              >Back to login</v-btn
            >
          </div>
        </v-card-title>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";
import AppPhoneNumberInput from "@/components/common/AppPhoneNumberInput.vue";
export default {
  components: {
    AppPhoneNumberInput,
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      snackbar: false,
      errorMessage: null,
      city: "",
      zip: "",
      state: "",
      address: "",
      address2: "",
      contact_person: "",
      contact_title: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
      phone: "",
      phone_ext: "",
      phonePayload: {},
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI",
      ],
    };
  },
  methods: {
    ...mapActions("auth", {
      registerAgency: "onRegisterAgency",
    }),
    onChange() {
      this.snackbar = false;
      this.errorMessage = null;
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.errorMessage = message;
    },
    onBack() {
      this.$router.push({ name: "login" });
    },
    onSignUp() {
      if (!this.name) {
        this.showSnackbar("Name is required");
        return;
      }
      if (!this.email) {
        this.showSnackbar("Email is required");
        return;
      }
      if (!this.phone) {
        this.showSnackbar("Phone is required");
        return;
      }
      if (!this.contact_person) {
        this.showSnackbar("Contact person is required");
        return;
      }
      if (!this.contact_title) {
        this.showSnackbar("Contact title is required");
        return;
      }
      if (!this.address) {
        this.showSnackbar("Address is required");
        return;
      }
      if (!this.city) {
        this.showSnackbar("City is required");
        return;
      }
      if (!this.state) {
        this.showSnackbar("State is required");
        return;
      }
      if (!this.zip) {
        this.showSnackbar("City is required");
        return;
      }
      if (!this.password) {
        this.showSnackbar("Password is required");
        return;
      }
      if (!this.confirmPassword) {
        this.showSnackbar("Confirm Password");
        return;
      }
      if (!this.phonePayload.isValid) {
        this.showSnackbar("Invalid phone");
        return;
      }
      if (this.rules.email(this.email) != true) {
        this.showSnackbar("Invalid email");
        return;
      }
      if (this.password.length < 8) {
        this.showSnackbar("Password required more than 8 letters");
        return;
      }
      if (this.password != this.confirmPassword) {
        this.showSnackbar("Password does not match");
        return;
      }
      this.loading = true;
      let phone = this.phone;
      if (
        this.phonePayload.isValid &&
        this.phonePayload.countryCallingCode != "1"
      ) {
        phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
      }
      this.registerAgency({
        name: this.name,
        email: this.email,
        phone,
        phone_ext: this.phone_ext,
        contact_person: this.contact_person,
        contact_title: this.contact_title,
        address: this.address,
        address2: this.address2,
        city: this.city,
        state: this.state,
        zip: this.zip,
        password: this.password,
      })
        .then(() => {
          this.loading = false;
          this.$router.push({
            name: "agency-home",
            query: { welcome: true },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showSnackbar(error.response.data.message);
          }
        });
    },
  },
};
</script>
<style>
.bg-blur {
  background: #fffc;
  position: absolute;
  height: 100%;
  width: 100%;
}
.bg-cover > .v-image__image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.v-responsive__content {
  height: 100%;
}
</style>
